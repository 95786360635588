<template>
  <section>
    <BaseProductPage :cols="cols" :formField="formField" :resetUrl="url" :isSearch="isSearch">
      <template #tips>
        <el-alert title="上架后的原型，无法编辑效果图以及校验效果图" type="warning" show-icon></el-alert>
      </template>
      <template v-slot="{ sup_this }">
        <el-table-column fixed="right" align="center" label="操作" width="330px">
          <template slot-scope="{ row }">
            <color-text-btn
              class="line"
              type="info"
              size="mini"
              v-if="!isLack(row) && checkPermission(['externaladmin:product:maintain:proShowMange:downloadCoord'])"
              @click="downloadCoord(row)"
              >下载坐标图</color-text-btn
            >
            <color-text-btn
              class="line"
              style="margin-left: 0px"
              v-if="psdExist(row) && checkPermission(['externaladmin:product:maintain:proShowMange:updateCoord'])"
              :type="isLack(row) ? 'info' : 'danger'"
              @click="linkToCodeKnifePic(row)"
              >{{ isLack(row) ? '创建坐标图' : '更新坐标图' }}</color-text-btn
            >
            <color-text-btn type="warning" size="mini" class="line" v-if="!psdExist(row)" @click="psdFileEmpty"
              >未设置标准尺码</color-text-btn
            >
            <ProShowEdit
              v-if="someCoordExist(row) && psdExist && checkPermission(['externaladmin:product:maintain:proShowMange:editImg'])"
              :sup_this="sup_this"
              :data="row"
              class="figure-edit"
            />
            <CheckReference
              v-p="['externaladmin:product:maintain:proShowMange:checkEffect']"
              :data="row"
              :isLack="isLack(row)"
            ></CheckReference>
          </template>
        </el-table-column>
      </template>
    </BaseProductPage>
  </section>
</template>

<script>
import BaseProductPage from '../../baseProductPage'
import ProShowEdit from './module/proShowEdit'
import CheckReference from './module/checkReferenceBtn'
import { checkPermission } from '@/utils'
import { createRandomNum } from '@/utils'
import { mapGetters } from 'vuex'

import { proShowMangeField as formField } from '../../field'
import { proShowMangeCols as cols } from '../../cols'
export default {
  name: 'proShowMange',
  components: {
    BaseProductPage,
    ProShowEdit,
    CheckReference
  },
  data() {
    return {
      isSearch: this.checkPermission(['externaladmin:product:maintain:proShowMange:search']),
      url: '/externaladmin/productService/productPrototype/listForShow'
    }
  },
  computed: {
    ...mapGetters(['isMain']),
    cols({ isMain }) {
      if (!isMain) {
        return cols.filter((item) => item.value !== 'manager')
      }
      return cols
    },
    formField({ isMain }) {
      if (!isMain) {
        return formField.filter((item) => item.value !== 'manager')
      }
      return formField
    },
    isLack() {
      return (row) => {
        try {
          const { styleList = [] } = row || {}
          const isLack = styleList.every(({ showImageGroupList = [] }) => {
            return showImageGroupList && showImageGroupList[0].coordinateImagePath
          })
          return !isLack
        } catch (err) {
          return true
        }
      }
    },
    someCoordExist() {
      return (row) => {
        try {
          const { styleList = [] } = row || {}
          const isExist = styleList.some(({ showImageGroupList = [] }) => {
            showImageGroupList = Array.isArray(showImageGroupList) ? showImageGroupList : []
            if (!showImageGroupList.length) {
              return false
            }
            return showImageGroupList && showImageGroupList[0].coordinateImagePath
          })
          return isExist
        } catch (err) {
          return false
        }
      }
    },
    psdExist() {
      return (row) => {
        return row.referSize && row.referSize.psdPath
      }
    }
  },
  methods: {
    downloadCoord(row) {
      try {
        const {
          styleList: [
            {
              showImageGroupList: [{ coordinateKnifeLayoutImagePath, whiteKnifeLayoutImagePath, coordinateImagePath }]
            }
          ]
        } = row
        this.downloadByPath([coordinateKnifeLayoutImagePath, whiteKnifeLayoutImagePath, coordinateImagePath])
      } catch (err) {
        this.$message.warning('下载坐标图失败')
      }
      console.log('row', row)
    },
    // downloadByPath(paths) {
    //   const alinks = [];
    //   for (let i = 0; i < paths.length; i++) {
    //     const path = `${paths[i]}?uid=${createRandomNum()}`;
    //     console.log('path', path)
    //     const aLink = document.createElement("a");
    //     aLink.className = "thunder-link";
    //     aLink.setAttribute("href", path);
    //     document.body.append(aLink);
    //     alinks.push(aLink);
    //     setTimeout(() => {
    //       aLink.click();
    //     }, 50 * i + 1);
    //   }

    //   this.$nextTick(() => {
    //     thunderLink();

    //     this.$nextTick(() => {
    //       alinks.map((aLink, i) => {
    //         setTimeout(() => {
    //           document.body.removeChild(aLink);
    //         }, 50 * i + 1);
    //       });
    //     });
    //   });
    // },
    downloadByPath(paths) {
      const tasks = paths.map((url) => {
        return {
          name: '',
          url: `${url}?uid=${createRandomNum()}`
        }
      })
      thunderLink.newTask({
        downloadDir: '', // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
        tasks
      })
    },
    psdFileEmpty() {
      this.$message.warning('标准尺码未设置，请前往刀版图管理页面处理。')
    },
    async linkToCodeKnifePic(row) {
      const { referSize, styleList = [] } = row || {}
      // if (
      //   prim_struct[0] &&
      //   Array.isArray(prim_struct[0].prim_show_groups) &&
      //   prim_struct[0].prim_show_groups[0] &&
      //   prim_struct[0].prim_show_groups[0].prim_show_img.length
      // ) {
      //   return this.$message.warning("请先清空效果图，再进行此操作！！");
      // }
      this.$router.push(
        `/product/maintain/createCoordPic?id=${referSize && referSize.id}&&structId=${styleList.map(
          ({ id }) => id
        )}&&protoId=${row.id}`
      )
    }
  }
}
</script>

<style scoped>
.el-alert {
  margin-top: -20px;
  margin-bottom: 20px;
}
</style>
